import React from "react";
import { defineMessages } from "react-intl";
import { LinkTo } from "@opr-finance/component-link-to";
import { InnerText } from "@opr-finance/component-fonts";

export const messages = defineMessages({
    pageTitle: {
        id: "page.change.title",
    },
    pageSubTitle: {
        id: "page.change.subtitle",
    },
    pageBody: {
        id: "page.change.body",
    },
    phoneNumber: {
        id: "page.change.phone.number",
        values: {
            strong: (value) => <InnerText variant="bold">{value}</InnerText>,
        },
    },
    email: {
        id: "page.change.email",
        values: {
            strong: (value) => <InnerText variant="bold">{value}</InnerText>,
            email: (value) => (
                <LinkTo variant="basic" href={`mailto: ${value}`}>
                    {value}
                </LinkTo>
            ),
        },
    },
    openingHours: {
        id: "page.change.opening.hours",
    },
    applicationSentTitle: {
        id: "page.change.modal.application.sent.title",
    },
    applicationSent: {
        id: "page.change.modal.application.sent.text",
    },
    applicationStartTitle: {
        id: "page.change.modal.application.start.title",
    },
    applicationModalInfo: {
        id: "page.change.modal.application.info",
    },
    applicationModalNewLimit: {
        id: "page.change.modal.application.new.limit",
    },
    applicationAllowDowngrade: {
        id: "page.change.modal.application.allow.downgrade",
    },
    applicationEmploymentType: {
        id: "page.change.modal.application.employment.type",
    },
    applicationMonthlyIncomeGross: {
        id: "page.change.modal.application.monthly.income.gross",
    },
    applicationLivingExpenses: {
        id: "page.change.modal.application.living.expenses",
    },
    applicationLoanExpenses: {
        id: "page.change.modal.application.loan.expenses",
    },
    applicationGuaranteeObligation: {
        id: "page.change.modal.application.guarantee.obligation",
    },
    applicationGuaranteeObligationAmount: {
        id: "page.change.modal.application.guarantee.obligation.amount",
    },
    applicationLoanPurpose: {
        id: "page.change.modal.application.loan.purpose",
    },
    applicationMaritialStatus: {
        id: "page.change.modal.application.maritial.status",
    },
    applicationChildren: {
        id: "page.change.modal.application.children",
    },
    applicationAllowBisnodeCheck: {
        id: "page.change.modal.application.allow.bisnode.check",
    },
    applicationSendButton: {
        id: "page.change.modal.application.button.send",
    },
    applicationCancelButton: {
        id: "page.change.modal.application.button.cancel",
    },
    changePageApplicationTitle: {
        id: "page.change.application.title",
    },
    changePageApplicationIngress: {
        id: "page.change.application.ingress",
    },
    changePageApplicationIngress2: {
        id: "page.change.application.ingress2",
    },
    changePageCurrentLimit: {
        id: "page.change.current.limit",
    },
    changePageNewLimit: {
        id: "page.change.new.limit",
    },
    changePageAllowDowngrade: {
        id: "page.change.allow.downgrade",
    },
    changePageEmploymentType: {
        id: "page.change.employment.type",
    },
    changePageMonthlyIncomeGross: {
        id: "page.change.monthly.income.gross",
    },
    changePageLivingExpenses: {
        id: "page.change.living.expenses",
    },
    changePageLoanExpenses: {
        id: "page.change.loan.expenses",
    },
    changePageGuaranteeObligation: {
        id: "page.change.guarantee.obligation",
    },
    chnagePageGuaranteeObligationAmount: {
        id: "page.change.guarantee.obligation.amount",
    },
    changePageLoanPurpose: {
        id: "page.change.loan.purpose",
    },
    changePageMaritialStatus: {
        id: "page.change.maritial.status",
    },
    changePageChildren: {
        id: "page.change.children",
    },
    changePageAllowBisnodeCheck: {
        id: "page.change.allow.bisnode.check",
    },
    changePageValidationError: {
        id: "page.change.validation.error.message",
    },
});
