export { isValidFinnishCompanyId, isValidFinnishIban, isValidPhoneNumberFi } from "./fi";
export { isValidSwedishBankAccount, isValidPhoneNumberSe } from "./se";
export { isValidPhoneNumberNl } from "./nl";

export function testMoney(value: string | undefined): boolean {
    const reg = new RegExp("^[0-9]+$");
    if (!value) return true;
    const includesNumbersOnly = reg.test(value);
    if (value === "") return true;
    if (value.length > 0 && includesNumbersOnly) return true;
    return false;
}

export function allowNumbersOnly(value: string | null): boolean {
    if (!value) return false;
    const expr = /^\d+$/;
    const reg = new RegExp(expr);
    const result = reg.exec(value);
    return result ? true : false;
}

export function validateMobilePhone(value: string | null): boolean {
    if (!value) return false;
    const regExp = new RegExp(/^0[\d+]{9}$/);
    return regExp.test(value);
}

export function isValidYear(value: string | null): boolean {
    if (!value) return false;
    const regExp = new RegExp(/^\d{4}$/);
    return regExp.test(value);
}

export function isValidMonth(value: string | null): boolean {
    if (!value) return false;
    const regExp = new RegExp(/(^0[0-9]|1[0-2]$)/);
    return regExp.test(value);
}

export const checkYear = (year: string | null): boolean => {
    if (!year) return false;
    const regExp = new RegExp(/^\d{4}$/);
    if (!regExp.test(year)) return false;
    if (Number(year) < 1900) return false;
    if (Number(year) > new Date().getFullYear()) return false;
    return true;
};

export const checkMonth = (month: string | null): boolean => {
    if (!month) return false;
    const regExp = new RegExp(/(^0[0-9]|1[0-2]$)/);
    if (!regExp.test(month)) return false;
    if (month && month.length !== 2) return false;
    if (Number(month) === 0) return false;
    if (Number(month) > 12) return false;
    return true;
};

export function isValidFinnishSSN(value: string | undefined): boolean {
    if (!value) return false;
    const regExp = new RegExp(/^[0-9]{6}[+A-][0-9]{3}[A-z0-9]$/);
    return regExp.test(value);
}
