import { FeatureLoginState } from "@opr-finance/feature-lfp-login";
import { FeatureBalanceState } from "@opr-finance/feature-balance";
import { FeatureNewsState, FeatureTranslationV2State } from "@opr-finance/feature-contentful";
import { FeatureStatementsState } from "@opr-finance/feature-statements";
import { FeatureInitializerState } from "@opr-finance/feature-initializer";
import { FeatureDocumentState } from "@opr-finance/feature-document";
import { FeatureTransactionsState } from "@opr-finance/feature-transactions";
import { FeatureWithdrawState } from "@opr-finance/feature-withdraw";
import { FeatureLoaderState } from "@opr-finance/feature-loader";

export type AppState = FeatureLoginState &
    FeatureBalanceState &
    FeatureNewsState &
    FeatureTranslationV2State &
    FeatureStatementsState &
    FeatureTransactionsState &
    FeatureDocumentState &
    FeatureInitializerState &
    FeatureWithdrawState &
    FeatureLoaderState;

export type IncreaseCreditLimitFormData = {
    newCreditLimit: string;
    allowDowngrade: boolean;
    employmentType: string;
    monthlyIncomeGross: string;
    livingExpenses: string;
    loanExpenses: string;
    guarantorObligation: string;
    guarantorObligationAmount: string;
    loanPurpose: string;
    otherLoanPurpose: string;
    maritalStatus: string;
    children: string;
    allowBisnodeCheck: boolean;
};

export enum EmploymentOptionsPlain {
    FULL = "Tillsvidareanställd",
    TRIAL = "Provanställd",
    RETIRED = "Pensionerad",
    PROJECT = "Projektanställd",
    SELF_EMPLOYED = "Egenföretagare",
    NO_WORK = "Arbetslös",
    STUDENT = "Student",
    TEMPORARY = "Vikarie",
}

export enum EmploymentOptions {
    FULL = "FULL",
    TRIAL = "TRIAL",
    RETIRED = "RETIRED",
    PROJECT = "PROJECT",
    SELF_EMPLOYED = "SELF_EMPLOYED",
    NO_WORK = "NO_WORK",
    STUDENT = "STUDENT",
    TEMPORARY = "TEMPORARY",
}

export enum MaritialStatusPlain {
    DIVORCED = "Skild",
    MARRIED = "Gift",
    PARTNER = "Sambo",
    SINGLE = "Singel",
    WIDOWED = "Änka/Änkling",
}

export enum MaritialStatus {
    DIVORCED = "DIVORCED",
    MARRIED = "MARRIED",
    PARTNER = "PARTNER",
    SINGLE = "SINGLE",
    WIDOWED = "WIDOWED",
}

export type MaritialStatusReturn = keyof typeof MaritialStatus;

export type IncreaseLimitApplicant = {
    personalID: string;
    givenName: string;
    surName: string;
    email: string;
    phone: string;
};

export type IncreaseLimitApplicantBankAccount = {
    bankAccount: string;
};

export type IncreaseLimitApplication = IncreaseCreditLimitFormData &
    IncreaseLimitApplicant &
    IncreaseLimitApplicantBankAccount;
