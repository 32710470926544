import React, { useState, useLayoutEffect } from "react";
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import {
    loginActions,
    LoginActionConstants,
    customerActions,
} from "@opr-finance/feature-lfp-login";
import { ContentfulActionConstants } from "@opr-finance/feature-contentful";
import { LogoutPage } from "./pages/LogoutPage/LogoutPage";
import { Footer } from "@opr-finance/component-footer";
import { Layout } from "@opr-finance/layout-everyday";
import { Header } from "@opr-finance/component-header";
import { iconLibrary } from "@opr-finance/feature-icon-library";
import {
    MobileNavigation,
    IconContainer,
    MorePageLink,
    MorePageIcon,
} from "@opr-finance/component-mobile-navigation";
import { Text } from "@opr-finance/component-fonts";
import { Icon } from "@opr-finance/component-icon";
import { AppInitializer } from "@opr-finance/feature-initializer";
import { BalanceActionConstants, balanceActions } from "@opr-finance/feature-balance";
import { StatementsActionConstants, statementsActions } from "@opr-finance/feature-statements";
import { documentActions } from "@opr-finance/feature-document";
import {
    TransactionsActionConstants,
    transactionsActions,
} from "@opr-finance/feature-transactions";
import { withdrawActions } from "@opr-finance/feature-withdraw";
import { translationActions } from "@opr-finance/feature-contentful";
import { LoginPage } from "./pages/LoginPage";
import { FrontPage } from "./pages/Frontpage/FrontPage";
import { UserPage } from "./pages/UserPage/UserPage";
import { StartPage } from "./pages/StartPage/StartPage";
import logo from "./images/EverydayPlus.svg";
import bg from "./images/background.svg";
import { ChangePage } from "./pages/ChangePage/ChangePage";
import { AppState } from "./types/general";
import { FooterContent, AfterFooter } from "./components/footer/index";
import { ContactPage } from "./pages/ContactPage/ContactPage";
import { AppActionConstants } from "./actions/actions";
import { LoanPage } from "./pages/LoanPage/LoanPage";
import { NoLoanPage } from "./pages/NoLoanPage/NoLoanPage";
import { ErrorPage } from "./pages/ErrorPage/ErrorPage";
import { NotFoundPage } from "./pages/NotFoundPage/NotFoundPage";
import { ExpiredPage } from "./pages/ExpiredPage/ExpiredPage";
import { appMessages } from "./app.messages";
import { Logo } from "@opr-finance/component-logo";
import { Button } from "@opr-finance/component-button";
import { LinkTo } from "@opr-finance/component-link-to";
import { AppLoader } from "@opr-finance/feature-loader";
import CookieBot from "react-cookiebot";
import * as Leandev from "@opr-finance/api-definitions";

iconLibrary.initEverydayplus();

const domainGroupId = process.env.REACT_APP_COOKIEBOT_DOMAING_GROUP_ID as string;

const App: React.FC = () => {
    const dispatch = useDispatch();

    const mock = (process.env.REACT_APP_MOCK as string) === "1" ? true : false;
    const gwUrl = process.env.REACT_APP_LEANDEV_API_URL as string;
    const frendsBaseUrl = process.env.REACT_APP_FRENDS_BASE_API_URL as string;
    const generatorUrlBase = process.env.REACT_APP_FRENDS_BASE_API_URL as string;
    const source = process.env.REACT_APP_SOURCE_PORTAL as string;
    const language = process.env.REACT_APP_LOCALE as string;

    useLayoutEffect(() => {
        dispatch(
            loginActions.loginInitializer({
                mockApiCalls: mock,
                loginUrl: process.env.REACT_APP_REDIRECT as string,
                successUrl: "/front",
                noLoanUrl: "/no-loan",
                errorUrl: "/",
                expiredTokenUrl: "/expired",
                gwUrl,
            })
        );
        dispatch(
            customerActions.customerInitializer({
                productType: "CREDIT_LOAN",
            })
        );
        dispatch(
            translationActions.fetchTranslationTrigger({
                clientParams: {
                    space: process.env.REACT_APP_CONTENTFUL_SPACE as string,
                    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN as string,
                },
                contentParams: {
                    select: "fields",
                    content_type: "translationV2",
                    "fields.application[in]": "Everyday Plus Online Sweden",
                    "fields.language[in]": "sv",
                    limit: 500,
                },
            })
        );
    }, [dispatch, mock, gwUrl]);
    const token = useSelector((state: AppState) => {
        return state.login.token;
    });

    // /**

    //  * NOTE: This is quick fix for customers that have ED+ and Fixza
    //  * Need to refactor if same user can have multiple CREDIT_LOAN type
    //  */
    // const activeAccounts = useSelector((state: AppState) => {
    //     const accountList = state.customer.user?.accountList;
    //     console.log(accountList);

    //     /**
    //      * sorts the accountList so that items are in descending order based on their ID's
    //      */
    //     const sortedAccountList = accountList?.sort(function (
    //         a: Leandev.AccountInfo,
    //         b: Leandev.AccountInfo
    //     ) {
    //         return Number(b.id) - Number(a.id);
    //     });

    //     const creditLoans =
    //         sortedAccountList?.filter((account) => account.type === "CREDIT_LOAN") || [];
    //     return creditLoans;
    // });

    // const accountNumber = activeAccounts.length > 0 ? activeAccounts[0].accountNumber : undefined;

    const { accountNumber, accountId } = useSelector(
        (state: AppState) => state.activeAccount.accountInfo
    );

    const activeAccount = useSelector((state: AppState) => state.activeAccount.account);

    const defaultPromissoryNoteId = useSelector(
        (state: AppState) => state.balance.balance?.promissoryNoteDocumentId
    );

    useLayoutEffect(() => {
        dispatch(
            balanceActions.balanceInitializer({
                mockApiCalls: mock,
                noLoanUrl: "/no-loan",
                gwUrl,
                token,
                accountId,
                account: activeAccount,
            })
        );
        dispatch(
            documentActions.fetchDocumentInitializer({
                mockApiCalls: mock,
                gwUrl,
                token,
                generatorUrlBase,
                source,
                language,
            })
        );
        dispatch(
            documentActions.fetchPromissoryNoteIdInitializer({
                mockApiCalls: mock,
                gwUrl,
                token,
                defaultPromissoryNoteId,
                accountNumber,
            })
        );
        dispatch(
            statementsActions.fetchStatementsInitializer({
                mockApiCalls: mock,
                gwUrl,
                token,
                accountId,
            })
        );
        dispatch(
            transactionsActions.fetchTransactionsInitializer({
                mockApiCalls: mock,
                gwUrl,
                token,
                countPerPage: 10,
                shownInStatement: true,
                excludeTransactionTypes: "ReservedTrancheTransaction",
                accountId,
            })
        );
        dispatch(
            withdrawActions.withdrawInitializer({
                mockApiCalls: mock,
                gwUrl: `${frendsBaseUrl}/api/everydayplusse/v2/withdraw`,
                token,
            })
        );
    }, [
        dispatch,
        token,
        mock,
        gwUrl,
        generatorUrlBase,
        source,
        language,
        accountId,
        defaultPromissoryNoteId,
        accountNumber,
        frendsBaseUrl,
        activeAccount,
    ]);

    const isFrontPage = useRouteMatch({
        path: "/front",
        strict: true,
        sensitive: true,
    });
    const isStartPage = useRouteMatch({
        path: "/",
        strict: true,
        sensitive: true,
    });
    const isLoanPage = useRouteMatch({
        path: "/loan",
        strict: true,
        sensitive: true,
    });
    const isUserPage = useRouteMatch({
        path: "/user",
        strict: true,
        sensitive: true,
    });
    const isLogoutPage = useRouteMatch({
        path: "/logout",
        strict: true,
        sensitive: true,
    });
    const isLoginPage = useRouteMatch({
        path: "/logout",
        strict: true,
        sensitive: true,
    });
    const isNoLoanPage = useRouteMatch({
        path: "/no-loan",
        strict: true,
        sensitive: true,
    });
    const isExpiredPage = useRouteMatch({
        path: "/expired",
        strict: true,
        sensitive: true,
    });
    const isErrorPage = useRouteMatch({
        path: "/error",
        strict: true,
        sensitive: true,
    });
    const [isMorePageVisible, setIsMorePageVisible] = useState(false);
    const history = useHistory();

    const { formatMessage: fm } = useIntl();

    const isLoginButton = isLoginPage?.isExact
        ? true
        : false || isStartPage?.isExact
        ? true
        : false || isNoLoanPage?.isExact
        ? true
        : false || isExpiredPage?.isExact
        ? true
        : false || isErrorPage?.isExact
        ? true
        : false;

    let loginButtonText = "LOGGA IN";
    let loginButtonIcon = ["fa", "user"];
    let marketingPageLink = process.env.REACT_APP_MARKETING_PAGE as string;

    if (isNoLoanPage?.isExact) {
        loginButtonText = "LOGGA UT";
    } else if (isLogoutPage?.isExact) {
        loginButtonText = "LOGGA IN IGEN";
    } else if (token) {
        loginButtonText = "LOGGA UT";
        loginButtonIcon = ["fa", "sign-out-alt"];
    }

    const isLoading = useSelector((state: AppState) => {
        return state.loader.loading;
    });

    const navigationItems = [
        { linkTo: "/front", navigationText: fm(appMessages.navigationFront) },
        { linkTo: "/loan", navigationText: fm(appMessages.navigationLan) },
        { linkTo: "/change", navigationText: fm(appMessages.navigationChange) },
        { linkTo: "/user", navigationText: fm(appMessages.navigationUser) },
        {
            linkTo: "/contact",
            navigationText: fm(appMessages.navigationCustomerservice),
        },
    ];

    const collectionNavigationItems = [
        { linkTo: "/front", navigationText: fm(appMessages.navigationFront) },
        { linkTo: "/user", navigationText: fm(appMessages.navigationUser) },
        {
            linkTo: "/contact",
            navigationText: fm(appMessages.navigationCustomerservice),
        },
    ];

    if (isLoading) {
        return <AppLoader />;
    }

    return (
        <Layout
            bodyBackgroundImage={bg}
            header={
                <Header
                    navigationItems={
                        activeAccount?.state === "COLLECTION"
                            ? collectionNavigationItems
                            : navigationItems
                    }
                    logo={
                        <Logo
                            logoSrc={logo}
                            width={[113, 165]}
                            onClick={() => {
                                if (token) {
                                    window.location.href = "/front";
                                } else {
                                    window.location.href = "/";
                                }
                            }}
                        />
                    }
                    button={
                        !isStartPage?.isExact ? (
                            <>
                                <>
                                    {isLogoutPage && (
                                        <LinkTo
                                            variant="primary"
                                            fontSize="16px"
                                            marginTop="8px"
                                            marginRight="8px"
                                            href={marketingPageLink}>
                                            {fm(appMessages.navigationBackLink)}
                                        </LinkTo>
                                    )}
                                </>
                                <Button
                                    variant={isLoginButton ? "login" : "logout"}
                                    onClick={() => {
                                        if (token) {
                                            window.location.href = "/logout";
                                        } else {
                                            window.location.href = "/";
                                        }
                                    }}>
                                    <>
                                        {loginButtonText} <Icon icon={loginButtonIcon} />
                                    </>
                                </Button>
                            </>
                        ) : (
                            <LinkTo
                                variant="primary"
                                fontSize="16px"
                                marginTop="10px"
                                href={marketingPageLink}>
                                {fm(appMessages.navigationBackLink)}
                            </LinkTo>
                        )
                    }
                    authenticated={token ? true : false}
                />
            }
            footer={<Footer columns={FooterContent} after={<AfterFooter />} />}>
            <>
                <CookieBot domainGroupId={domainGroupId} />
                <Switch>
                    <Route
                        path="/"
                        exact
                        render={() => {
                            return <StartPage />;
                        }}
                    />
                    <Route
                        path="/login"
                        render={() => {
                            return <LoginPage />;
                        }}
                    />
                    <Route
                        path="/user"
                        render={() => {
                            return (
                                <AppInitializer
                                    actionsRequired={[LoginActionConstants.LOGIN_COMPLETE_SUCCESS]}
                                    initializerAction={AppActionConstants.USER_PAGE_TRIGGER}>
                                    <UserPage />
                                </AppInitializer>
                            );
                        }}
                    />
                    <Route
                        path="/front"
                        render={() => {
                            return (
                                <AppInitializer
                                    actionsRequired={[
                                        LoginActionConstants.LOGIN_COMPLETE_SUCCESS,
                                        BalanceActionConstants.FETCH_ACCOUNT_SUCCESS,
                                        ContentfulActionConstants.FETCH_NEWS_SUCCESS,
                                        StatementsActionConstants.FETCH_STATEMENTS_SUCCESS,
                                    ]}
                                    initializerAction={AppActionConstants.FRONT_PAGE_TRIGGER}>
                                    <FrontPage />
                                </AppInitializer>
                            );
                        }}
                    />
                    <Route
                        path="/loan"
                        render={() => {
                            return (
                                <AppInitializer
                                    actionsRequired={[
                                        LoginActionConstants.LOGIN_COMPLETE_SUCCESS,
                                        BalanceActionConstants.FETCH_ACCOUNT_SUCCESS,
                                        StatementsActionConstants.FETCH_STATEMENTS_SUCCESS,
                                        TransactionsActionConstants.FETCH_TRANSACTIONS_SUCCESS,
                                    ]}
                                    initializerAction={AppActionConstants.LOAN_PAGE_TRIGGER}>
                                    <LoanPage />
                                </AppInitializer>
                            );
                        }}
                    />
                    <Route path="/contact" component={ContactPage} />
                    <Route
                        path="/change"
                        render={() => {
                            return (
                                <AppInitializer
                                    actionsRequired={[
                                        LoginActionConstants.LOGIN_COMPLETE_SUCCESS,
                                        BalanceActionConstants.FETCH_ACCOUNT_SUCCESS,
                                        StatementsActionConstants.FETCH_STATEMENTS_SUCCESS,
                                    ]}
                                    initializerAction={AppActionConstants.CHANGE_PAGE_TRIGGER}>
                                    <ChangePage />
                                </AppInitializer>
                            );
                        }}
                    />
                    <Route
                        path="/logout"
                        render={() => {
                            return <LogoutPage />;
                        }}
                    />
                    <Route
                        path="/error"
                        exact
                        render={() => {
                            return <ErrorPage />;
                        }}
                    />
                    <Route
                        path="/no-loan"
                        render={() => {
                            return <NoLoanPage />;
                        }}
                    />
                    <Route
                        path="/expired"
                        render={() => {
                            return <ExpiredPage />;
                        }}
                    />
                    <Route component={NotFoundPage} />
                </Switch>
            </>
            <MobileNavigation
                isVisible={!isFrontPage || !isLogoutPage}
                isMorePageVisible={isMorePageVisible}
                onCloseClick={() => setIsMorePageVisible(false)}
                morePageTitle={fm(appMessages.mobileNavigationMore)}
                morePageLinksTitle={fm(appMessages.mobileNavigationMoreTitle)}
                morePageLinks={[
                    <MorePageLink
                        onClick={() => {
                            history.push("/user");
                            setIsMorePageVisible(false);
                        }}>
                        <MorePageIcon>
                            <Icon icon={["fas", "user"]} size="sm" />
                        </MorePageIcon>
                        <Text variant="mobileNavigationMoreLink" color="#0c445c">
                            {fm(appMessages.mobileNavigationMorePageUser)}
                        </Text>
                    </MorePageLink>,
                    <MorePageLink
                        onClick={() => {
                            history.push("/contact");
                            setIsMorePageVisible(false);
                        }}>
                        <MorePageIcon>
                            <Icon icon={["fas", "comments"]} size="sm" />
                        </MorePageIcon>
                        <Text variant="mobileNavigationMoreLink" color="#0c445c">
                            {fm(appMessages.mobileNavigationMorePageCustomerService)}
                        </Text>
                    </MorePageLink>,
                ]}
                morePageHeader={
                    <Header
                        navigationItems={[
                            { linkTo: "/front", navigationText: fm(appMessages.navigationFront) },
                            { linkTo: "/loan", navigationText: fm(appMessages.navigationLan) },
                            { linkTo: "/change", navigationText: fm(appMessages.navigationChange) },
                            { linkTo: "/user", navigationText: fm(appMessages.navigationUser) },
                            {
                                linkTo: "/contact",
                                navigationText: fm(appMessages.navigationCustomerservice),
                            },
                        ]}
                        logo={
                            <Logo
                                logoSrc={logo}
                                width={[113, 165]}
                                onClick={() => {
                                    if (token) {
                                        window.location.href = "/front";
                                    } else {
                                        window.location.href = "/";
                                    }
                                }}
                            />
                        }
                        button={
                            !isStartPage?.isExact ? (
                                <Button
                                    variant={isLoginButton ? "login" : "logout"}
                                    onClick={() => {
                                        if (token) {
                                            window.location.href = "/logout";
                                        } else {
                                            window.location.href = "/";
                                        }
                                    }}>
                                    {isNoLoanPage?.isExact && (
                                        <>
                                            LOGGA IN <Icon icon={["fa", "user"]} />
                                        </>
                                    )}
                                    {isLogoutPage?.isExact && (
                                        <>
                                            LOGGA IN IGEN <Icon icon={["fa", "user"]} />
                                        </>
                                    )}
                                    {token && (
                                        <>
                                            LOGGA UT <Icon icon={["fa", "sign-out-alt"]} />
                                        </>
                                    )}
                                </Button>
                            ) : (
                                <LinkTo variant="primary" fontSize="16px" marginTop="10px" href="/">
                                    {fm(appMessages.navigationBackLink)}
                                </LinkTo>
                            )
                        }
                        authenticated={token ? true : false}
                    />
                }
                morePageAfter={""}
                icons={[
                    <IconContainer
                        icon={["fas", "home"]}
                        size={"lg"}
                        text={"Start"}
                        active={isFrontPage?.isExact ? true : false}
                        onClick={() => {
                            history.push("/front");
                            setIsMorePageVisible(false);
                        }}
                    />,
                    <IconContainer
                        icon={["far", "money-bill-alt"]}
                        size={"lg"}
                        text={"Kredit"}
                        active={isLoanPage?.isExact ? true : false}
                        onClick={() => {
                            history.push("/loan");
                            setIsMorePageVisible(false);
                        }}
                    />,
                    <IconContainer
                        icon={["fas", "sync-alt"]}
                        size={"lg"}
                        text={"Tjänster"}
                        active={isUserPage?.isExact ? true : false}
                        onClick={() => {
                            history.push("/change");
                            setIsMorePageVisible(false);
                        }}
                    />,
                    <IconContainer
                        icon={["fas", "ellipsis-h"]}
                        size={"sm"}
                        text={"Se mer"}
                        active={isMorePageVisible ? true : false}
                        onClick={() => {
                            setIsMorePageVisible(!isMorePageVisible);
                        }}
                    />,
                ]}
            />
        </Layout>
    );
};

export default App;
