import * as VP from "@opr-finance/api-definitions";
import {
    EmploymentOptions,
    EmploymentOptionsPlain,
    MaritialStatus,
    MaritialStatusPlain,
    IncreaseLimitApplication,
    MaritialStatusReturn,
} from "./types/general";

export function mapEmploymentType(value: string) {
    switch (value) {
        case EmploymentOptionsPlain.FULL:
            return EmploymentOptions.FULL;
        case EmploymentOptionsPlain.NO_WORK:
            return EmploymentOptions.NO_WORK;
        case EmploymentOptionsPlain.PROJECT:
            return EmploymentOptions.PROJECT;
        case EmploymentOptionsPlain.RETIRED:
            return EmploymentOptions.RETIRED;
        case EmploymentOptionsPlain.SELF_EMPLOYED:
            return EmploymentOptions.SELF_EMPLOYED;
        case EmploymentOptionsPlain.STUDENT:
            return EmploymentOptions.STUDENT;
        case EmploymentOptionsPlain.TEMPORARY:
            return EmploymentOptions.TEMPORARY;
        case EmploymentOptionsPlain.TRIAL:
            return EmploymentOptions.TRIAL;
        default:
            return EmploymentOptions.NO_WORK;
    }
}

type T_AdditionalFields = {
    ip: string;
    propertyCost: number;
    creditMonthlyCost: number;
    monthlyIncome: number;
    allowDowngrade: boolean;
    guarantorObligations: number;
    purposeOfLoan: string;
    purposeOfLoanOther: string;
};

type T_DynamicFields = {
    dynamicFields: {
        additionalInfo: T_AdditionalFields;
    } | null;
};

type T_VpSubmitRevolvingApplicationRequestV2 =
    VP.components["schemas"]["SubmitRevolvingApplicationRequestV2"] & T_DynamicFields;

export function mapMaritialStatus(value: string): MaritialStatusReturn {
    switch (value) {
        case MaritialStatusPlain.DIVORCED:
            return MaritialStatus.DIVORCED;
        case MaritialStatusPlain.MARRIED:
            return MaritialStatus.MARRIED;
        case MaritialStatusPlain.PARTNER:
            return MaritialStatus.PARTNER;
        case MaritialStatusPlain.SINGLE:
            return MaritialStatus.SINGLE;
        case MaritialStatusPlain.WIDOWED:
            return MaritialStatus.WIDOWED;
        default:
            return MaritialStatus.SINGLE;
    }
}

export function mapApplicationToLeandevRequest(
    data: IncreaseLimitApplication
): T_VpSubmitRevolvingApplicationRequestV2 {
    const useDynamicFields =
        (process.env.REACT_APP_USE_DYNAMIC_FIELDS as string) === "1" ? true : false;
    const credits = parseInt(data.loanExpenses.replace(/\s+/g, ""));
    const propertyCost = parseInt(data.livingExpenses.replace(/\s+/g, "")) + credits;

    const dynamicFields: T_DynamicFields = {
        dynamicFields: {
            additionalInfo: {
                ip: "",
                propertyCost: parseInt(data.livingExpenses),
                creditMonthlyCost: parseInt(data.loanExpenses),
                monthlyIncome: parseInt(data.monthlyIncomeGross),
                allowDowngrade: data.allowDowngrade ? true : false,
                guarantorObligations:
                    data.guarantorObligation === "Nej"
                        ? 0
                        : parseInt(data.guarantorObligationAmount),
                purposeOfLoan: data.loanPurpose,
                purposeOfLoanOther: data.otherLoanPurpose ?? "none",
            },
        },
    };

    const application: T_VpSubmitRevolvingApplicationRequestV2 = {
        appliedProductID: process.env.REACT_APP_PRODUCT_ID as string,
        desiredLoan: parseInt(data.newCreditLimit.replace(/\s+/g, "")),
        desiredPaybackTime: 1,
        statementChannel: "ELECTRONIC_EMAIL_NOTIFICATION",
        applicationCategory: "TOP_UP",
        credits: [
            {
                sum: 0,
                rate: 0,
                paybackTime: 0,
                bank: "customer expenses",
                monthlyCost: credits,
            },
        ],
        applicants: {
            main: {
                personalID: data.personalID,
                givenName: data.givenName,
                surName: data.surName,
                email: data.email,
                phonePrimary: data.phone,
                maritalStatus: mapMaritialStatus(data.maritalStatus),
                children: parseInt(data.children),
                propertyCost,
                employer: {
                    employer: "No employer name",
                    employerEndDate: undefined,
                    employmentType: mapEmploymentType(data.employmentType),
                    salary: parseInt(data.monthlyIncomeGross.replace(/\s+/g, "")),
                    referencePhone: "+468225115",
                },
            },
        },
        disbursementAccount: {
            type: "SE-CLEARING",
            number: data.bankAccount,
        },
        dynamicFields: dynamicFields.dynamicFields,
        extras: {
            IP: "",
            propertyCost: data.livingExpenses.toString(),
            creditsMonthlyCost: data.loanExpenses.toString(),
            monthlyIncome: data.monthlyIncomeGross.toString(),
            allowDowngrade: data.allowDowngrade ? "Yes" : "No",
            guarantorObligations:
                data.guarantorObligation === "Nej" ? "0" : data.guarantorObligationAmount,
            purposeOfLoan:
                data.loanPurpose === "Annat" ? `Annat:${data.otherLoanPurpose}` : data.loanPurpose,
        },
    };

    return application;
}
