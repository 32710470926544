import { SystemStyleObject } from "@styled-system/css";
import { alignItems } from "styled-system";

export function body(): SystemStyleObject {
    return {
        display: "flex",
        fontFamily: "arial",
        fontSize: "16px",
        fontWeight: "normal",
        lineHeight: "1.5",
        color: "#0c445c",
        padding: "0 0 4px 0",
    };
}

export function bodyLabel(): SystemStyleObject {
    return {
        display: "flex",
        fontFamily: "arial",
        fontSize: "16px",
        fontWeight: "normal",
        lineHeight: "1.5",
        color: "#0c445c",
        padding: "0 0 6px 0",
    };
}

export function bodyValue(): SystemStyleObject {
    return {
        display: "flex",
        fontFamily: "arial",
        fontSize: "16px",
        fontWeight: "bold",
        lineHeight: "1.5",
        color: "#0c445c",
        padding: "0 0 6px 0",
    };
}

export function bodyTitle(): SystemStyleObject {
    return {
        display: "flex",
        fontFamily: "arial",
        fontSize: "16px",
        fontWeight: "bold",
        lineHeight: "1.5",
        color: "#0c445c",
        padding: "0 24px 24px 0",
    };
}

export function bodyWarning(): SystemStyleObject {
    return {
        display: "flex",
        fontFamily: "arial",
        fontSize: "16px",
        fontWeight: "normal",
        lineHeight: "1.5",
        color: "#0c445c",
        padding: "0 0 4px 0",
    };
}

export function bodySubmitError(): SystemStyleObject {
    return {
        fontFamily: "arial",
        fontSize: "16px",
        fontWeight: "normal",
        lineHeight: "1.5",
        color: "#0c445c",
    };
}

export function pageTitle(): SystemStyleObject {
    return {
        fontFamily: "arial",
        fontSize: "24px",
        fontWeight: "bold",
        color: "#0c445c",
        padding: "8px 0 0 0",
    };
}

export function mobilePageTitle(): SystemStyleObject {
    return {
        fontFamily: "arial",
        fontSize: "20px",
        fontWeight: "600",
        lineHeight: "2",
        color: "#fff",
    };
}

export function textField(): SystemStyleObject {
    return {
        width: "180px",
        height: "32px",
        margin: "0",
        padding: "8px",
        borderRadius: "5px",
        boxShadow: "0 1px 2px 0 rgba(12, 68, 92, 0.5)",
        border: "solid 1px #a9d3e5",
        backgroundColor: "#ffffff",
        fontFamily: "arial",
        fontSize: "16px",
        fontWeight: "bold",
        color: "#0c445c",
    };
}

export function select(): SystemStyleObject {
    return {
        width: "180px",
        height: "32px",
        margin: "0",
        padding: "4px 8px 4px 8px",
        borderRadius: "5px",
        boxShadow: "0 1px 2px 0 rgba(12, 68, 92, 0.5)",
        border: "solid 1px #a9d3e5",
        backgroundColor: "#ffffff",
        cursor: "pointer",
        appearance: "none",
        fontFamily: "arial",
        fontSize: "16px",
        fontWeight: "bold",
        color: "#0c445c",
    };
}

export function singleValue(): SystemStyleObject {
    return {
        fontFamily: "arial",
    };
}

export function singleOption(): SystemStyleObject {
    return {
        fontFamily: "arial",
    };
}

export function checkbox(): SystemStyleObject {
    return {
        width: "32px",
        height: "32px",
        margin: "0",
        borderRadius: "4px",
        boxShadow: "0 1px 2px 0 rgba(12, 68, 92, 0.5)",
        border: "solid 1px #a9d3e5",
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    };
}

export function checkboxDisabled(): SystemStyleObject {
    return {
        width: "24px",
        height: "24px",
        borderRadius: "4px",
        border: "solid 2px #979797",
        boxShadow: "2px 2px 1px 0 rgba(0, 0, 0, 0.1), inset 0 1px 4px 0 rgba(0, 0, 0, 0.3)",
        backgroundColor: "#d9d9d9",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };
}

export function checkboxText(): SystemStyleObject {
    return {
        fontFamily: "arial",
        fontSize: "16px",
        fontWeight: "400",
        color: "#0c445c",
        padding: "0 0 0 8px",
    };
}

export function button(): SystemStyleObject {
    return {
        border: "0px",
        borderRadius: "5px",
        backgroundColor: "transparent",
        backgroundImage: "linear-gradient(to top, #59b268, #afe681)",
        height: "34px",
        "&:hover": {
            backgroundImage:
                "linear-gradient(to top, rgba(89, 178, 104, 0.7), rgba(175, 230, 129, 0.7));",
        },
    };
}

export function buttonText(): SystemStyleObject {
    return {
        fontFamily: "arial",
        fontWeight: "bold",
        fontSize: "16px",
        color: "#fff",
        textTransform: "uppercase",
    };
}

export function formError(): SystemStyleObject {
    return {
        fontFamily: "arial",
        fontWeight: "bold",
        fontSize: "14px",
        color: "darkred",
        padding: "8px 0 0 16px",
    };
}

export function bottomFormError(): SystemStyleObject {
    return {
        fontFamily: "arial",
        fontWeight: "bold",
        fontSize: "14px",
        color: "darkred",
        padding: "8px 0 8px 0",
    };
}

export function link(): SystemStyleObject {
    return {
        fontFamily: "arial",
        fontSize: "16px",
        fontWeight: "bold",
        lineHeight: "1.5",
        color: "#196da8",
        cursor: "pointer",
    };
}

export function box(): SystemStyleObject {
    return {
        width: "100%",
        height: "100%",
        borderRadius: "8px",
        boxShadow: "0 3px 4px 0 rgba(0, 0, 0, 0.2)",
        backgroundColor: "#fdfdfd",
        backgroundImage: "linear-gradient(to bottom, #fdfdfd, #f9f9f9)",
        padding: "32px",
    };
}

export function sideBox(): SystemStyleObject {
    return {
        width: "100%",
        height: "100%",
        borderRadius: "8px",
        boxShadow: "0 3px 4px 0 rgba(0, 0, 0, 0.2)",
        backgroundColor: "#fdfdfd",
        backgroundImage: "linear-gradient(to bottom, #fdfdfd, #f9f9f9)",
        padding: "32px 32px 32px 32px",
    };
}

export function breadcrumbBox(): SystemStyleObject {
    return {
        width: "100%",
        borderRadius: "8px",
        boxShadow: "0 3px 4px 0 rgba(0, 0, 0, 0.2)",
        backgroundColor: "#fdfdfd",
        backgroundImage: "linear-gradient(to bottom, #fdfdfd, #f9f9f9)",
        padding: "12px",
        height: ["154px", "94px"],
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    };
}

export function inlineBox(): SystemStyleObject {
    return {
        width: "100%",
        height: "100%",
        borderRadius: "8px",
        boxShadow: "0 3px 4px 0 rgba(0, 0, 0, 0.2)",
        backgroundColor: "#fdfdfd",
        backgroundImage: "linear-gradient(to bottom, #fdfdfd, #f9f9f9)",
        padding: "18px 16px 18px 16px",
        margin: "0 0 16px 0",
    };
}

export function sideTitle(): SystemStyleObject {
    return {
        display: "flex",
        width: "100%",
        height: "100%",
        fontFamily: "arial",
        fontSize: "16px",
        fontWeight: "bold",
        lineHeight: "1.5",
        color: "#0c445c",
        padding: "0 0 16px 0",
    };
}

export function sideLink(): SystemStyleObject {
    return {
        color: "#0c445c",
        textDecoration: "none",
        "&:hover": {
            color: "#0c445c",
            textDecoration: "none",
        },
    };
}

export function breadcrumbTitle(): SystemStyleObject {
    return {
        display: "flex",
        fontFamily: "arial",
        fontSize: "16px",
        fontWeight: "normal",
        lineHeight: "1.5",
        color: "#0c445c",
        padding: ["0 10px 4px 0", "0 40px 0 0"],
        "&:first-of-type": {
            marginLeft: [0, "32px"],
        },
    };
}

export function headerTitle(): SystemStyleObject {
    return {
        display: "flex",
        fontFamily: "arial",
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "1.3",
        color: "#0c445c",
        padding: "0 0 0 40px",
        margin: "auto 0",
    };
}

export function buttonInfo(): SystemStyleObject {
    return {
        border: "0",
        borderRadius: "5px",
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.3)",
        backgroundColor: "#c3c8b4",
        color: "#fff",
        letterSpacing: "1.5",
        fontFamily: "arial",
        fontSize: "16px",
        padding: "8px 16px 8px 16px",
        margin: "0",
    };
}

export function buttonCancel(): SystemStyleObject {
    return {
        width: "150px",
        height: "40px",
        border: "0",
        borderRadius: "5px",
        boxShadow: "2px 2px 1px 0 rgba(0, 0, 0, 0.1), inset 0 1px 4px 0 rgba(0, 0, 0, 0.3)",
        backgroundColor: "#fff",
        fontFamily: "arial",
        fontSize: "16px",
        color: "#cc0000",
        cursor: "pointer",
    };
}

export function buttonSubmit(): SystemStyleObject {
    return {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        maxWidth: "240px",
        height: "64px",
        border: "0",
        borderRadius: "5px",
        backgroundColor: "#94b93a",
        textShadow: "0 1px 2px rgba(0,0,0,0.30)",
        fontFamily: "arial",
        fontSize: "24px",
        fontWeight: "bold",
        color: "#fff",
        margin: "0 0 32px 0",
        cursor: "pointer",
        ":hover": {
            backgroundColor: "rgba(148, 185, 58, 0.7)",
        },
        "&:active": {
            backgroundColor: "rgba(148, 185, 58, 0.8)",
        },
    };
}

export function buttonSubmitDisabled(): SystemStyleObject {
    return {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "240px",
        height: "64px",
        border: "0",
        borderRadius: "5px",
        backgroundColor: "#c3c8b4",
        fontFamily: "arial",
        fontSize: "24px",
        fontWeight: "bold",
        color: "#fff",
        margin: "0 0 32px 0",
        cursor: "not-allowed",
    };
}

export function headerContainer(): SystemStyleObject {
    return {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: ["50px", "76px"],
        boxShadow: "0 3px 4px 0 rgba(0, 0, 0, 0.2)",
        backgroundColor: "#f9f9f9",
    };
}

export function headerContent(): SystemStyleObject {
    return {
        maxWidth: "976px",
        width: "100%",
        height: "100%",
        padding: ["12px 0 12px 20px", "20px 20px 20px 40px"],
        display: "flex",
        flexDirection: "row",
    };
}

export function textFieldsContainer(): SystemStyleObject {
    return {
        display: "flex",
        border: "0",
        borderRadius: "4px",
        backgroundColor: "#fff",
        boxShadow: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.2)",
        padding: "6px 8px",
        width: ["85%", "240px"],
        fontSize: "16px",
        fontWeight: "normal",
        lineHeight: "1.5",
        marginBottom: "16px",
    };
}

export function multipleTextField(): SystemStyleObject {
    return {
        border: "0",
        width: "100%",
        backgroundColor: "#fff",
        color: "#0c445c",
        fontSize: "16px",
        fontWeight: "normal",
        lineHeight: "1.5",
        margin: "0",
        "&::placeholder": {
            color: "#737373",
        },
    };
}

export function modalOverlay(): SystemStyleObject {
    return {
        backgroundColor: "rgba(0,0,0,0.4)",
        display: "flex",
        zIndex: 1500,
    };
}

export function modalTitle(): SystemStyleObject {
    return {
        color: "#0c445c",
        fontSize: "18px",
        fontWeight: "bold",
        lineHeight: 1.25,
        margin: "0 0 8px 0",
        display: "flex",
        flexDirection: "row",
        fontFamily: "arial",
    };
}
export function modalTitleText(): SystemStyleObject {
    return {
        width: "100%",
        textAlign: "left",
    };
}

export function modalContent(): SystemStyleObject {
    return {
        position: ["absolute", "relative"],
        padding: ["16px", "32px"],
        margin: "auto",
        backgroundColor: "#fdfeff",
        borderRadius: [0, "8px"],
        boxShadow: "0 3px 4px 0 rgba(0, 0, 0, 0.2)",
        width: ["100%", "664px"],
        left: [0, "auto"],
        right: [0, "auto"],
        top: ["64px", "auto"],
        bottom: ["56px", "auto"],
        overflowY: "scroll",
        maxHeight: ["auto", "100vh"],
        color: "#0c445c",
        fontFamily: "arial",
    };
}

export function modalCloseIcon(): SystemStyleObject {
    return {
        cursor: "pointer",
        marginLeft: "auto",
    };
}
