import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Authenticator } from "@opr-finance/feature-lfp-login";
import { BoxContainer } from "@opr-finance/component-box-container";
import { Grid, GridContent, GridContainer, FlexColumn } from "@opr-finance/component-grid";
import { PageTitle, SectionBox } from "@opr-finance/component-content";
import { Text } from "@opr-finance/component-fonts";
import { Balance } from "@opr-finance/feature-balance";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../types/general";
import { format } from "date-fns";
import { RecentNews } from "@opr-finance/feature-contentful";
import { Withdraw, withdrawActions, WithdrawResponseStatus } from "@opr-finance/feature-withdraw";
import { Icon } from "@opr-finance/component-icon";
import { messages } from "./messages";
import { Button } from "@opr-finance/component-button";
import { DateFormatter } from "@opr-finance/component-date";
import { documentActions, DocumentType } from "@opr-finance/feature-document";
import * as VP from "@opr-finance/api-definitions";
import { currency, CurrencyLocale, CurrencyFormat } from "@opr-finance/component-currency";
import { GiosgData } from "../../components/GiosgData/GiosgData";
import { CollectionBlock } from "../CollectionBlock/CollectionBlock";
import { T_VpInvoice, T_VpInvoices } from "@opr-finance/feature-statements";

const getInvoice = (invoices: T_VpInvoices) => {
    /**
     * jos on yksi maksamaton lasku - näytetään sen tiedot buttonissa
     * jos ei ole laskuja - buttonia ei näytetä
     * jos viimeisin lasku on maksettu, mutta aikaisempi ei - näytetäänkö sitten se joku aikaisempi? - JOO, JOS VIIMEISIN ON MAKSETTU JA AIEMPI EI, NIIN PITÄISI NÄYTTÄÄ SIT SE AIKAISEMPI MAKSAMATON LASKU. 
     * jos on maksamaton lasku (overdue) ja uusi lasku - kumman tiedot näytetään? VOIKO NÄYTTÄÄ MOLEMPIEN TIETOJA? TARKOITAN MOLEMPIEN LASKUJEN YHTEISSUMMAA? LFPSSÄ SE EI OIKEIN ONNISTU, NE ON ERILLISIÄ LASKUJA AINA.. MUTTA JOS EI VOI NÄYTTÄÄ YHTEISSUMMAA JA YKSI PITÄÄ VALITA, NIIN NÄYTETÄÄN SIT SE UUSIN LASKU.
     * jos osa laskusta on maksettu - näytetäänkö jäljellä jäävä summa vai se alkuperainen. JOS MAHDOLLISTA, NIIN JÄLJELLÄ OLEVA SUMMA.

     */
    if (invoices.length === 0) return null;

    const unpaidInvoice: T_VpInvoice | null = invoices.reduce(
        (invoice: T_VpInvoice | null, current: T_VpInvoice | null) => {
            if (!invoice && current?.status === "UNPAID" && current?.type === "REGULAR") {
                invoice = current;
            }
            return invoice;
        },
        null
    );

    if (unpaidInvoice) {
        return unpaidInvoice;
    }

    const nextUnpaidInvoice: T_VpInvoice | null = invoices.reduce(
        (invoice: T_VpInvoice | null, current: T_VpInvoice | null) => {
            if (
                (!invoice && current?.status === "OVERDUE" && current?.type === "REGULAR") ||
                (!invoice && current?.status === "PARTIALLYPAID" && current?.type === "REGULAR")
            )
                invoice = current;
            return invoice;
        },
        null
    );

    if (nextUnpaidInvoice) {
        return nextUnpaidInvoice;
    }

    return null;
};

export function FrontPage() {
    const dispatch = useDispatch();

    const user = useSelector((state: AppState) => state.customer.user);
    const balance = useSelector((state: AppState) => state.balance.balance);
    const invoices = useSelector((state: AppState) => state.statements.formatted);
    const withdraw = useSelector((state: AppState) => state.withdraw.withdraw);
    const promissoryNoteId = useSelector((state: AppState) => state.document.documentId);
    let invoice: T_VpInvoice | null = getInvoice(invoices);
    let invoiceAmount = "";

    const availableBalance =
        balance && balance.creditLimit && typeof balance.remainingPrincipal === "number"
            ? balance.creditLimit - balance.remainingPrincipal
            : 0;

    const roundedBalance = Math.floor(availableBalance / 100) * 100;

    const availableBalanceCurrency = currency({
        value: roundedBalance,
        locale: CurrencyLocale.Sweden,
        currency: CurrencyFormat.Sweden,
        style: "decimal",
    });

    const [isWithdrawn, setIsWithdrawn] = useState(false);

    const { formatMessage: fm } = useIntl();

    useEffect(() => setIsWithdrawn(false), [withdraw]);

    if (!balance) {
        return null;
    }

    if (!user) {
        return null;
    }

    if (invoice) {
        invoiceAmount = currency({
            value: invoice.unpaidAmount ? invoice.unpaidAmount : 0,
            locale: CurrencyLocale.Sweden,
            currency: CurrencyFormat.Sweden,
        });
    }

    if (balance.state === "COLLECTION") return <CollectionBlock />;

    return (
        <Authenticator>
            <FlexColumn>
                <PageTitle
                    title={fm(messages.pageTitle, {
                        firstName: user?.customerBasicInfo?.givenName,
                    })}
                />
                <GridContainer flex={1}>
                    <Grid width={3 / 4}>
                        <GridContent>
                            <BoxContainer
                                display={"flex"}
                                flexDirection={"column"}
                                alignItems={"center"}
                                justifyContent={"center"}>
                                <Text variant={"bodyTitle"} paddingBottom="24px">
                                    {fm(messages.pageLoanSubtitle)}
                                </Text>
                                <Text variant={"body"} paddingBottom="8px">
                                    {fm(messages.pageLoanInfo)}
                                </Text>
                                <Text variant={"bodyTitle"} paddingBottom={"24px"}>
                                    {currency({
                                        value: availableBalance,
                                        locale: CurrencyLocale.Sweden,
                                        currency: CurrencyFormat.Sweden,
                                    })}
                                </Text>
                                <Text variant="body" paddingBottom="8px">
                                    {fm(messages.pageLoanBalance)}
                                </Text>
                                <Text variant="small" paddingBottom="24px">
                                    {format(new Date(), "dd.MM.yyyy")}
                                </Text>
                                <Balance
                                    translation={{
                                        minText: fm(messages.balanceMin),
                                        maxText: fm(messages.balanceMax),
                                    }}
                                />
                                <Text
                                    variant={"body"}
                                    paddingBottom={"40px"}
                                    paddingX={["16px", "0px"]}
                                    width="100%"
                                    maxWidth="360px"
                                    lineHeight="1.5"
                                    textAlign="center">
                                    {fm(messages.pageLoanBottomInfo)}
                                </Text>
                                {invoice && (
                                    <Button
                                        variant="large"
                                        width="100%"
                                        maxWidth="200px"
                                        marginBottom="46px"
                                        onClick={() => {
                                            if (!invoice) return null;
                                            dispatch(
                                                documentActions.fetchDocumentTrigger({
                                                    documentId: invoice.documentId as string,
                                                    documentType: DocumentType.INVOICE,
                                                })
                                            );
                                        }}>
                                        <Text variant="body" marginTop="24px">
                                            {fm(messages.pageFrontNextInvoice)}
                                        </Text>
                                        <Text variant="bodyTitle">{invoiceAmount}</Text>
                                        <Text variant="small" marginBottom="24px">
                                            <DateFormatter date={invoice.createdDate} />
                                        </Text>
                                    </Button>
                                )}
                            </BoxContainer>
                        </GridContent>
                    </Grid>
                    <Grid width={1 / 4}>
                        <GridContent>
                            <RecentNews
                                translation={{
                                    title: fm(messages.recentNewsTitle),
                                    readMore: fm(messages.recentnewsReadMore),
                                }}
                            />
                        </GridContent>
                    </Grid>
                </GridContainer>
                <GridContainer>
                    <Grid width={"100%"}>
                        <GridContent>
                            <BoxContainer>
                                <Text variant="bodyTitle" textAlign="center">
                                    {fm(messages.pageWithdrawTitle)}
                                </Text>
                                <GridContainer>
                                    <Grid
                                        width={1 / 2}
                                        justifyContent={"center"}
                                        alignContent={"center"}>
                                        {withdraw.status === WithdrawResponseStatus.SUCCESS && (
                                            <Text variant="bodyTitle" margin="24px">
                                                {fm(messages.withdrawDone)}
                                            </Text>
                                        )}
                                        {withdraw.status === WithdrawResponseStatus.ERROR && (
                                            <Text variant="bodyTitle" margin="24px">
                                                {fm(messages.withdrawError)}
                                            </Text>
                                        )}
                                        {withdraw.status === WithdrawResponseStatus.INITIAL && (
                                            <Withdraw
                                                plusIcon={<Icon icon={["fa", "plus"]} size="2x" />}
                                                minusIcon={
                                                    <Icon icon={["fa", "minus"]} size="2x" />
                                                }
                                                defaultValue={1000}
                                                increment={100}
                                                min={1000}
                                                max={roundedBalance}
                                                isLoading={isWithdrawn}
                                                onSubmit={(value: number): void => {
                                                    console.log("got value!", value);
                                                    setIsWithdrawn(true);
                                                    dispatch(
                                                        withdrawActions.withdrawTrigger({
                                                            phoneNumber: `${user.customerBasicInfo.mobilePhoneCode}${user.customerBasicInfo.mobilePhoneNumber}`,
                                                            amount: value,
                                                        })
                                                    );
                                                }}
                                                unit="kr"
                                                translation={{
                                                    title: fm(messages.withdrawTitle),
                                                    buttonText: fm(messages.withdrawButton),
                                                    iban: fm(messages.withdrawIban, {
                                                        ...messages.withdrawIban.values,
                                                        iban: "3300 7405154084",
                                                    }),
                                                    validationMax: fm(messages.withdrawInfoMax),
                                                    validationInsufficientBalance: fm(
                                                        messages.withdrawInsufficientBalance
                                                    ),
                                                }}
                                            />
                                        )}
                                    </Grid>
                                    <Grid width={1 / 2}>
                                        <SectionBox
                                            flexDirection={"column"}
                                            padding={["16px", "0px"]}
                                            marginBottom={"24px"}>
                                            <Text variant={"body"} listItem={true}>
                                                {fm(messages.withdrawListItem1, {
                                                    maxLimit: availableBalanceCurrency,
                                                })}
                                            </Text>
                                            <Text variant={"body"} listItem={true}>
                                                {fm(messages.withdrawListItem2, {
                                                    maxLimit: availableBalanceCurrency,
                                                })}
                                            </Text>
                                            <Text variant={"body"} listItem={true}>
                                                {fm(messages.withdrawListItem3, {
                                                    maxLimit: availableBalanceCurrency,
                                                })}
                                            </Text>
                                            <Text variant={"body"} listItem={true}>
                                                {fm(messages.withdrawListItem4, {
                                                    maxLimit: availableBalanceCurrency,
                                                })}
                                            </Text>
                                        </SectionBox>
                                    </Grid>
                                </GridContainer>
                            </BoxContainer>
                        </GridContent>
                    </Grid>
                </GridContainer>
                <GiosgData
                    customerName={`${user?.customerBasicInfo?.givenName} ${user?.customerBasicInfo?.surName}`}
                    customerId={user?.customerBasicInfo?.customerId}
                />
            </FlexColumn>
        </Authenticator>
    );
}
