import * as VP from "@opr-finance/api-definitions";

export enum SendIncreaseLimitApplicationResult {
    Default = "",
    Error = "Error",
    BankAccountError = "BankAccountError",
    Approved = "APPROVED",
    Referral = "REFERRAL",
    Rejected = "REJECTED",
    Unprocessed = "UNPROCESSED",
}

export async function sendIncreaseLimitApplication(
    application: VP.components["schemas"]["SubmitRevolvingApplicationRequestV2"],
    token: string
): Promise<SendIncreaseLimitApplicationResult> {
    if (process.env.REACT_APP_MOCK === "1") {
        console.log("got application to send", application);

        if (false) {
            return await new Promise((resolve) => {
                return setTimeout(() => resolve(SendIncreaseLimitApplicationResult.Error), 1000);
            });
        }

        if (false) {
            return await new Promise((resolve) => {
                return setTimeout(() => resolve(SendIncreaseLimitApplicationResult.Rejected), 500);
            });
        }

        return await new Promise((resolve) => {
            return setTimeout(() => resolve(SendIncreaseLimitApplicationResult.Referral), 3000);
        });
    }

    try {
        const data = await fetch(
            `${process.env.REACT_APP_FRENDS_BASE_API_URL}/api/everydayplusse/v2/increase-credit-limit`,
            {
                method: "POST",
                body: JSON.stringify(application),
                headers: {
                    Authorization: token,
                },
            }
        );

        const responseStatus = data.status;
        const response = await data.json();

        const result = response.decision as SendIncreaseLimitApplicationResult;
        console.log("Helloooo", response, responseStatus);
        switch (responseStatus) {
            case 200: {
                switch (result) {
                    case SendIncreaseLimitApplicationResult.Approved: {
                        //initGA(response.applicationID, application.desiredLoan, "sent_accepted");
                        break;
                    }
                    case SendIncreaseLimitApplicationResult.Referral: {
                        //initGA(response.applicationID, application.desiredLoan, "sent_refferal");
                        break;
                    }
                    case SendIncreaseLimitApplicationResult.Rejected: {
                        //initGA(response.applicationID, application.desiredLoan, "sent_rejected");
                        break;
                    }
                    default:
                    //initGA(response.applicationID, application.desiredLoan, "sent");
                }
                return result;
            }
            case 400: {
                if (
                    response &&
                    response.message &&
                    response.message === "Param disbursement number is not correct."
                ) {
                    /** NOTE: should not happen as bank account number is taken from store */
                    //initGA(0, application.desiredLoan, "sent_error_bankid");
                    return SendIncreaseLimitApplicationResult.BankAccountError;
                }
                //initGA(0, 0, "sent_error");
                return SendIncreaseLimitApplicationResult.Error;
            }
            default:
                // initGA(0, 0, "sent_error");
                return SendIncreaseLimitApplicationResult.Error;
        }
    } catch (e) {
        console.log("application sending failed?", e);
        //initGA(0, 0, "sent_error");
        return SendIncreaseLimitApplicationResult.Error;
    }
}
