import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import { ThemeProvider } from "styled-components";
import { everydayPlusTheme } from "@opr-finance/themes";
import "./index.css";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { AppState } from "./types/general";

function TranslatedApp() {
    const messages = useSelector((state: AppState) => state.translation.messages);
    const locale = process.env.REACT_APP_LOCALE as string;

    return (
        <IntlProvider locale={locale} messages={messages}>
            <Router>
                <App />
            </Router>
        </IntlProvider>
    );
}

ReactDOM.render(
    <ThemeProvider theme={everydayPlusTheme}>
        <Provider store={store}>
            <TranslatedApp />
        </Provider>
    </ThemeProvider>,
    document.getElementById("root")
);

serviceWorker.unregister();
