import { CurrencyLocale, CurrencyFormat } from "./types";

export type CurrencyProps = {
    value: string | number | undefined;
    locale: CurrencyLocale;
    currency: CurrencyFormat;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
    style?: string;
};

export const currency = (props: CurrencyProps): string => {
    if (typeof props.value === "undefined") return "Error";
    const parsedValue: number =
        typeof props.value === "number" ? props.value : parseFloat(props.value);
    return parsedValue.toLocaleString(props.locale, {
        style: props.hasOwnProperty("style") ? props.style : "currency",
        currency: props.currency,
        currencyDisplay: "symbol",
        minimumFractionDigits: props.hasOwnProperty("minimumFractionDigits")
            ? props.minimumFractionDigits
            : 2,
        maximumFractionDigits: props.hasOwnProperty("maximumFractionDigits")
            ? props.maximumFractionDigits
            : 2,
    });
};
