import { format, parseISO, getMonth, getYear } from "date-fns";
import { currency, CurrencyLocale, CurrencyFormat } from "@opr-finance/component-currency";

import { E_InvoicesItemConf, T_VpInvoice, T_VpInvoicesResponse } from "../types/invoices";

function filterPending(value: T_VpInvoice) {
    return value.type !== "PENDING";
}

export const formattedInvoices = (invoices: T_VpInvoicesResponse, currencyCountry: string) => {
    const invoicesFormatted =
        invoices.invoices &&
        invoices.invoices.filter(filterPending).map((item) => {
            const country = `${currencyCountry}`;
            const dueDate = item.dueDate
                ? format(parseISO(item.dueDate), E_InvoicesItemConf.DEFAULT_FORMAT_DATE)
                : undefined;
            const amount = currency({
                value: item.invoiceAmount,
                locale: CurrencyLocale[country],
                currency: CurrencyFormat[country],
            });
            const fees = currency({
                value: item.invoiceFee,
                locale: CurrencyLocale[country],
                currency: CurrencyFormat[country],
            });

            let title = `Flex `;

            if (dueDate && typeof item.createdDate !== "undefined") {
                const month = getMonth(parseISO(item.createdDate)) + 1;
                const year = getYear(parseISO(item.createdDate)) % 2000;
                title = `Flex ${month}/${year}`;
            }

            return {
                ...item,
                dueDate,
                amount,
                fees,
                title,
            };
        });
    return invoicesFormatted;
};
