import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { activeAccountReducer, Authenticator } from "@opr-finance/feature-lfp-login";
import { PageTitle } from "@opr-finance/component-content";
import { BoxContainer } from "@opr-finance/component-box-container";
import { Font } from "@opr-finance/component-fonts";
import { Grid, GridContent, GridContainer, FlexColumn } from "@opr-finance/component-grid";
import { Text } from "@opr-finance/component-fonts";
import { messages } from "./messages";
import {
    TextField,
    SelectField,
    CheckboxField,
    ButtonField,
    useForm,
    getOption,
    option,
    SelectOption,
} from "@opr-finance/component-forms";
import * as yup from "yup";
import {
    AppState,
    IncreaseCreditLimitFormData,
    EmploymentOptionsPlain,
    MaritialStatusPlain,
    IncreaseLimitApplication,
} from "../../types/general";
import {
    body,
    bodyTitle,
    bodyLabel,
    bodyValue,
    button,
    buttonSubmit,
    buttonSubmitDisabled,
    buttonCancel,
    buttonText,
    checkbox,
    checkboxText,
    formError,
    bottomFormError,
    select,
    singleValue,
    singleOption,
    textField,
    modalCloseIcon,
    modalContent,
    modalOverlay,
    modalTitle,
} from "@opr-finance/theme-everyday-online";
import { useSelector } from "react-redux";
import { currency, CurrencyFormat, CurrencyLocale } from "@opr-finance/component-currency";
import { pickOff } from "@opr-finance/utils";
import { Icon } from "@opr-finance/component-icon";
import { testMoney } from "@opr-finance/validators";
import { Modal } from "@opr-finance/component-modal-dialog";
import styled from "styled-components";
import { mapApplicationToLeandevRequest } from "../../increaseLimitApplicationMapper";
import {
    sendIncreaseLimitApplication,
    SendIncreaseLimitApplicationResult,
} from "../../api/increaseLimitApplication";
import { Tooltip } from "@opr-finance/component-tooltip";
import { T_VpStatementV2 } from "@opr-finance/feature-statements";
import { modalTitleText } from "@opr-finance/theme-everyday-online/src/styles";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 24px 24px 16px 24px;
    @media (max-width: 786px) {
        flex-direction: column;
    }
`;

const TooltipTitle = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 24px 24px 16px 24px;
`;

const Left = styled.div`
    width: 50%;
    @media (max-width: 786px) {
        flex-direction: column;
        width: 100%;
    }
`;

const Right = styled.div`
    width: 50%;
    @media (max-width: 786px) {
        flex-direction: column;
        width: 100%;
    }
`;

const ModalLeft = styled.div`
    width: 70%;
    @media (max-width: 786px) {
        flex-direction: column;
        width: 100%;
    }
`;

const ModalRight = styled.div`
    width: 30%;
    @media (max-width: 786px) {
        flex-direction: column;
        width: 100%;
    }
`;

const Root = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

const IconWrapper = styled.span`
    margin-right: 3px;
`;

export function ChangePage() {
    const { formatMessage: fm } = useIntl();
    const history = useHistory();

    const isRescoringAllowed =
        (process.env.REACT_APP_IS_RESCORING_ALLOWED as string) === "1" ? true : false;

    const balance = useSelector((state: AppState) => state.balance.balance);
    const creditLimit = balance?.creditLimit ? balance.creditLimit : 0;
    const applicant = useSelector((state: AppState) => state.customer.user);
    const disbursementAccount = useSelector((state: AppState) => state.balance.disbursementAccount);
    const token = useSelector((state: AppState) => state.login.token);
    const accountState = useSelector((state: AppState) => state.balance.balance?.state);
    const statements = useSelector((state: AppState) => state.statements.formatted);

    const [isValid, setIsValid] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [application, setApplication] = useState<IncreaseCreditLimitFormData>({
        allowBisnodeCheck: false,
        allowDowngrade: false,
        children: "",
        employmentType: "",
        livingExpenses: "",
        loanExpenses: "",
        guarantorObligation: "",
        guarantorObligationAmount: "",
        loanPurpose: "",
        otherLoanPurpose: "",
        maritalStatus: "",
        monthlyIncomeGross: "",
        newCreditLimit: "",
    });

    const [applicationSent, setApplicationSent] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false);

    async function handleForm() {
        const data: IncreaseLimitApplication = {
            givenName: `${applicant?.customerBasicInfo.givenName}`,
            surName: `${applicant?.customerBasicInfo.surName}`,
            personalID: `${applicant?.customerBasicInfo.century}${applicant?.customerBasicInfo.ssn}`,
            email: `${applicant?.customerBasicInfo.email}`,
            phone: `${applicant?.customerBasicInfo.mobilePhoneCode}${applicant?.customerBasicInfo.mobilePhoneNumber}`,
            bankAccount: `${disbursementAccount?.number}`,
            ...application,
        };

        setIsSending(true);
        const mappedApplication = mapApplicationToLeandevRequest(data);
        const result = await sendIncreaseLimitApplication(mappedApplication, token as string);

        console.log("result", result);

        if (
            result === SendIncreaseLimitApplicationResult.Referral ||
            result === SendIncreaseLimitApplicationResult.Rejected
        ) {
            setApplicationSent(true);
        }

        if (
            result === SendIncreaseLimitApplicationResult.Error ||
            result === SendIncreaseLimitApplicationResult.BankAccountError
        ) {
            history.push("/error");
        }
    }

    let sums: number[] = [5000, 8000, 10000, 15000, 20000, 25000];

    let validSums: SelectOption[] = sums
        .filter((sum) => {
            return sum > creditLimit;
        })
        .map((sum) => {
            return option(
                currency({
                    value: sum,
                    locale: CurrencyLocale.Sweden,
                    currency: CurrencyFormat.Sweden,
                })
            );
        });

    let amounts: SelectOption[] = [option("Välj"), ...validSums];

    const validAmounts: string[] = pickOff(
        amounts,
        (option: SelectOption) => option.value === "Välj"
    ).map((option: SelectOption) => {
        return option.value;
    });

    const types: SelectOption[] = [
        option("Välj"),
        option(EmploymentOptionsPlain.FULL),
        option(EmploymentOptionsPlain.RETIRED),
        option(EmploymentOptionsPlain.NO_WORK),
        option(EmploymentOptionsPlain.PROJECT),
        option(EmploymentOptionsPlain.TEMPORARY),
        option(EmploymentOptionsPlain.STUDENT),
        option(EmploymentOptionsPlain.SELF_EMPLOYED),
        option(EmploymentOptionsPlain.TRIAL),
    ];

    const validTypes: string[] = pickOff(
        types,
        (option: SelectOption) => option.value === "Välj"
    ).map((option: SelectOption) => {
        return option.value;
    });

    const maritalOptions: SelectOption[] = [
        option("Välj"),
        option(MaritialStatusPlain.SINGLE),
        option(MaritialStatusPlain.WIDOWED),
        option(MaritialStatusPlain.DIVORCED),
        option(MaritialStatusPlain.MARRIED),
        option(MaritialStatusPlain.PARTNER),
    ];

    const guarantorObligationOptions: SelectOption[] = [
        option("Välj"),
        option("Ja"),
        option("Nej"),
    ];

    const validGuarantorObligation: string[] = pickOff(
        guarantorObligationOptions,
        (option: SelectOption) => option.value === "Välj"
    ).map((option: SelectOption) => {
        return option.value;
    });

    const loanPurposeOptions: SelectOption[] = [
        option("Välj"),
        option("Köpa bil/båt/annat fordon"),
        option("Renovering"),
        option("Sjukvård/Tandvård"),
        option("Lösa andra skulder"),
        option("Utgifter till hemmet"),
        option("Annat", "Annat, specificera"),
    ];

    const validLoanPurpose: string[] = pickOff(
        loanPurposeOptions,
        (option: SelectOption) => option.value === "Välj"
    ).map((option: SelectOption) => {
        return option.value;
    });

    const validMarital: string[] = pickOff(
        maritalOptions,
        (option: SelectOption) => option.value === "Välj"
    ).map((option: SelectOption) => {
        return option.value;
    });

    const children: SelectOption[] = [
        option("Välj"),
        option("0"),
        option("1"),
        option("2"),
        option("3"),
        option("4"),
        option("5"),
    ];

    const validChildren: string[] = pickOff(
        children,
        (option: SelectOption) => option.value === "Välj"
    ).map((option: SelectOption) => {
        return option.value;
    });

    const schema = yup.object().shape({
        newCreditLimit: yup.string().oneOf(validAmounts, "Pakollinen kenttä"),
        allowDowngrade: yup
            .boolean()
            .oneOf([true, false], "pakollinen kenttä")
            .required("pakollinen kenttä"),
        allowBisnodeCheck: yup
            .boolean()
            .oneOf([true], "pakollinen kenttä")
            .required("pakollinen kenttä"),
        employmentType: yup
            .string()
            .oneOf(validTypes, "Pakko hyväksyy kaikki")
            .required("Pakko hyväksyy kaikki"),
        monthlyIncomeGross: yup.string().test("monthlyIncomeGrossTest", "Invalid", testMoney),
        livingExpenses: yup.string().test("monthlyIncomeGrossTest", "Invalid", testMoney),
        loanExpenses: yup.string().test("monthlyIncomeGrossTest", "Invalid", testMoney),
        guarantorLoanObligation: yup.string().oneOf(validGuarantorObligation, "Pakollinen kenttä"),
        loanPurpose: yup.string().oneOf(validLoanPurpose, "Pakollinen kenttä"),
        maritalStatus: yup.string().oneOf(validMarital, "Pakollinen kenttä"),
        children: yup.string().oneOf(validChildren, "Pakollinen kenttä"),
    });

    const { form, processChange, processFocus, processBlur, Error, processReset } =
        useForm<IncreaseCreditLimitFormData>({
            initial: {
                newCreditLimit: "Välj",
                allowDowngrade: false,
                allowBisnodeCheck: false,
                employmentType: "Välj",
                monthlyIncomeGross: "",
                livingExpenses: "",
                loanExpenses: "",
                guarantorObligation: "Välj",
                guarantorObligationAmount: "",
                loanPurpose: "Välj",
                otherLoanPurpose: "",
                maritalStatus: "Välj",
                children: "Välj",
            },
            schema,
            onChange: (data) => {
                setApplication(data);
                setIsValid(true);
            },
            onError: (data) => {
                setIsValid(false);
            },
            onBlur: () => {},
            onFocus: () => {},
            styleConfig: {
                body: body(),
                bodyTitle: bodyTitle(),
                button: button(),
                buttonText: buttonText(),
                checkbox: checkbox(),
                checkboxText: checkboxText(),
                formError: formError(),
                select: select(),
                textField: textField(),
            },
        });

    type StatementsStatus = {
        overdueDays: number; // need to be 0
        statementsCount: number; // need to be at least 3
    };

    const checkStatements = (statements: T_VpStatementV2[]): StatementsStatus => {
        let countableStatements = statements
            .slice(0, 4)
            .filter((statement) => statement?.status === "PAIDINFULL");

        if (countableStatements.length > 3) countableStatements = countableStatements.slice(0, 3);

        /* const overdueDays = countableStatements.reduce(
            (acc: number, curr) =>
                typeof curr.overdueDays !== "undefined" ? acc + curr.overdueDays : acc,
            0
        ); */
        const overdueDays = countableStatements[0].overdueDays || 0;

        return {
            overdueDays,
            statementsCount: countableStatements.length,
        };
    };

    const isCustomerEligible = (): boolean => {
        if (!isRescoringAllowed) return false;
        if (creditLimit === 25000) return false;
        if (accountState !== "OPEN") return false;
        if (statements.length < 3) return false;
        const statusOfStatements = checkStatements(statements);
        if (statusOfStatements.statementsCount !== 3 || statusOfStatements.overdueDays >= 4)
            return false;

        return true;
    };

    if (accountState === "COLLECTION") {
        history.push("/front");
    }

    return (
        <Authenticator>
            <FlexColumn>
                <PageTitle title={fm(messages.pageTitle)} />
                {!isCustomerEligible() && (
                    <GridContainer>
                        <Grid width={"100%"}>
                            <GridContent>
                                <BoxContainer>
                                    <Text variant={"bodyTitle"}>{fm(messages.pageSubTitle)}</Text>
                                    <Text variant={"body"} padding="0 0 16px 24px">
                                        {fm(messages.pageBody)}
                                    </Text>
                                    <Text variant={"body"} padding="0 0 16px 24px">
                                        {fm(messages.phoneNumber, messages.phoneNumber.values)}
                                    </Text>
                                    <Text variant={"body"} padding="0 0 16px 24px">
                                        {fm(messages.email, messages.email.values)}
                                    </Text>
                                    <Text variant={"body"} padding="0 0 40px 24px">
                                        {fm(messages.openingHours)}
                                    </Text>
                                </BoxContainer>
                            </GridContent>
                        </Grid>
                    </GridContainer>
                )}
                {isCustomerEligible() && (
                    <>
                        <Modal
                            modalTitle={
                                applicationSent
                                    ? fm(messages.applicationSentTitle)
                                    : fm(messages.applicationStartTitle)
                            }
                            isOpen={isModalOpen}
                            onClick={() => {
                                setIsModalOpen(false);
                                if (applicationSent) {
                                    processReset();
                                }
                            }}
                            styleConfig={{
                                closeIcon: modalCloseIcon(),
                                overlay: modalOverlay(),
                                content: modalContent(),
                                title: modalTitle(),
                                titleText: modalTitleText(),
                            }}>
                            {applicationSent && (
                                <>
                                    <Container>
                                        <Font styleConfig={{ root: body() }}>
                                            {fm(messages.applicationSent)}
                                        </Font>
                                    </Container>
                                </>
                            )}
                            {!applicationSent && (
                                <>
                                    <Container>
                                        <Font styleConfig={{ root: body() }}>
                                            {fm(messages.applicationModalInfo)}
                                        </Font>
                                    </Container>
                                    <Container>
                                        <ModalLeft>
                                            <Font styleConfig={{ root: bodyLabel() }}>
                                                {fm(messages.applicationModalNewLimit)}
                                            </Font>
                                        </ModalLeft>
                                        <ModalRight>
                                            <Font styleConfig={{ root: bodyValue() }}>
                                                {application.newCreditLimit}
                                            </Font>
                                        </ModalRight>
                                    </Container>
                                    <Container>
                                        <ModalLeft>
                                            <Font styleConfig={{ root: bodyLabel() }}>
                                                {fm(messages.applicationAllowDowngrade)}
                                            </Font>
                                        </ModalLeft>
                                        <ModalRight>
                                            <Font styleConfig={{ root: bodyValue() }}>
                                                {application.allowDowngrade ? "Ja" : "Nej"}
                                            </Font>
                                        </ModalRight>
                                    </Container>
                                    <Container>
                                        <ModalLeft>
                                            <Font styleConfig={{ root: bodyLabel() }}>
                                                {fm(messages.applicationEmploymentType)}
                                            </Font>
                                        </ModalLeft>
                                        <ModalRight>
                                            <Font styleConfig={{ root: bodyValue() }}>
                                                {application.employmentType}
                                            </Font>
                                        </ModalRight>
                                    </Container>
                                    <Container>
                                        <ModalLeft>
                                            <Font styleConfig={{ root: bodyLabel() }}>
                                                {fm(messages.applicationMonthlyIncomeGross)}
                                            </Font>
                                        </ModalLeft>
                                        <ModalRight>
                                            <Font styleConfig={{ root: bodyValue() }}>
                                                {application.monthlyIncomeGross}
                                            </Font>
                                        </ModalRight>
                                    </Container>
                                    <Container>
                                        <ModalLeft>
                                            <Font styleConfig={{ root: bodyLabel() }}>
                                                {fm(messages.applicationLivingExpenses)}
                                            </Font>
                                        </ModalLeft>
                                        <ModalRight>
                                            <Font styleConfig={{ root: bodyValue() }}>
                                                {application.livingExpenses}
                                            </Font>
                                        </ModalRight>
                                    </Container>
                                    <Container>
                                        <ModalLeft>
                                            <Font styleConfig={{ root: bodyLabel() }}>
                                                {fm(messages.applicationLoanExpenses)}
                                            </Font>
                                        </ModalLeft>
                                        <ModalRight>
                                            <Font styleConfig={{ root: bodyValue() }}>
                                                {application.loanExpenses}
                                            </Font>
                                        </ModalRight>
                                    </Container>
                                    <Container>
                                        <ModalLeft>
                                            <Font styleConfig={{ root: bodyLabel() }}>
                                                {fm(messages.applicationGuaranteeObligation)}
                                            </Font>
                                        </ModalLeft>
                                        <ModalRight>
                                            <Font styleConfig={{ root: bodyValue() }}>
                                                {application.guarantorObligation === "Nej"
                                                    ? "Nej"
                                                    : application.guarantorObligationAmount}
                                            </Font>
                                        </ModalRight>
                                    </Container>
                                    <Container>
                                        <ModalLeft>
                                            <Font styleConfig={{ root: bodyLabel() }}>
                                                {fm(messages.applicationLoanPurpose)}
                                            </Font>
                                        </ModalLeft>
                                        <ModalRight>
                                            <Font styleConfig={{ root: bodyValue() }}>
                                                {application.loanPurpose === "Annat"
                                                    ? `Annat:${application.otherLoanPurpose}`
                                                    : application.otherLoanPurpose}
                                            </Font>
                                        </ModalRight>
                                    </Container>
                                    <Container>
                                        <ModalLeft>
                                            <Font styleConfig={{ root: bodyLabel() }}>
                                                {fm(messages.applicationMaritialStatus)}
                                            </Font>
                                        </ModalLeft>
                                        <ModalRight>
                                            <Font styleConfig={{ root: bodyValue() }}>
                                                {application.maritalStatus}
                                            </Font>
                                        </ModalRight>
                                    </Container>
                                    <Container>
                                        <ModalLeft>
                                            <Font styleConfig={{ root: bodyLabel() }}>
                                                {fm(messages.applicationChildren)}
                                            </Font>
                                        </ModalLeft>
                                        <ModalRight>
                                            <Font styleConfig={{ root: bodyValue() }}>
                                                {application.children}
                                            </Font>
                                        </ModalRight>
                                    </Container>
                                    <Container>
                                        <ModalLeft>
                                            <Font styleConfig={{ root: bodyLabel() }}>
                                                {fm(messages.applicationAllowBisnodeCheck)}
                                            </Font>
                                        </ModalLeft>
                                        <ModalRight>
                                            <Font styleConfig={{ root: bodyValue() }}>
                                                {application.allowBisnodeCheck ? "Ja" : "Nej"}
                                            </Font>
                                        </ModalRight>
                                    </Container>
                                    <Column>
                                        <ButtonField
                                            type="submit"
                                            styleConfig={{
                                                root: isSending
                                                    ? buttonSubmitDisabled()
                                                    : buttonSubmit(),
                                            }}
                                            onClick={async (e) => {
                                                handleForm();
                                            }}>
                                            {isSending && (
                                                <IconWrapper>
                                                    <Icon icon={["fas", "circle-notch"]} spin />
                                                </IconWrapper>
                                            )}
                                            <span>{fm(messages.applicationSendButton)}</span>
                                        </ButtonField>
                                        <ButtonField
                                            styleConfig={{
                                                root: buttonCancel(),
                                            }}
                                            onClick={async (e) => {
                                                setIsModalOpen(false);
                                            }}>
                                            {fm(messages.applicationCancelButton)}
                                        </ButtonField>
                                    </Column>
                                </>
                            )}
                        </Modal>
                        <GridContainer>
                            <Grid width={"100%"}>
                                <GridContent>
                                    <BoxContainer>
                                        <TooltipTitle>
                                            <Text variant={"bodyTitle"}>
                                                {fm(messages.changePageApplicationTitle)}
                                            </Text>
                                            <Tooltip
                                                icon={<Icon icon={["far", "question-circle"]} />}
                                                styleConfig={{
                                                    root: {
                                                        padding: "24px 24px 0 0",
                                                    },
                                                    tooltipContainer: {},
                                                    tooltipContent: {
                                                        fontSize: "14px",
                                                    },
                                                }}>
                                                <strong>Månadsinkomst:</strong> Din inkomst före
                                                skatt per månad inklusive lön, pension, studiestöd
                                                osv.
                                                <br />
                                                <br />
                                                <strong>Boendekostnad:</strong> Inkludera din del av
                                                hyra eller månadsavgift samt eventuella kostnader
                                                för bolån och drift av egen fastighet.
                                                <br />
                                                <br />
                                                <strong>Lånekostnad:</strong> Inkludera din del av
                                                övriga lån tex. avbetalningar, kreditkort skulder,
                                                privåtlån.
                                            </Tooltip>
                                        </TooltipTitle>
                                        <Root>
                                            <Container>
                                                <Font styleConfig={{ root: body() }}>
                                                    {fm(messages.changePageApplicationIngress)}{" "}
                                                    <br />
                                                    <br />
                                                    {fm(messages.changePageApplicationIngress2)}
                                                </Font>
                                            </Container>
                                        </Root>
                                        <Root>
                                            <Container>
                                                <Left>
                                                    <Font styleConfig={{ root: bodyLabel() }}>
                                                        {fm(messages.changePageCurrentLimit)}
                                                    </Font>
                                                </Left>
                                                <Right>
                                                    <Font styleConfig={{ root: bodyLabel() }}>
                                                        {currency({
                                                            locale: CurrencyLocale.Sweden,
                                                            currency: CurrencyFormat.Sweden,
                                                            value: creditLimit,
                                                        })}
                                                    </Font>
                                                </Right>
                                            </Container>
                                            <Container>
                                                <Left>
                                                    <Font styleConfig={{ root: bodyLabel() }}>
                                                        {fm(messages.changePageNewLimit)}
                                                    </Font>
                                                </Left>
                                                <Right>
                                                    <SelectField
                                                        name="newCreditLimit"
                                                        value={getOption(
                                                            amounts,
                                                            form.data.newCreditLimit
                                                        )}
                                                        icon={
                                                            <Icon
                                                                icon={["fa", "caret-down"]}
                                                                size="1x"
                                                            />
                                                        }
                                                        inputConfig={{
                                                            select: {
                                                                value: form.data.newCreditLimit,
                                                                onChange: (value) => {
                                                                    processChange({
                                                                        field: "newCreditLimit",
                                                                        value: value?.value,
                                                                        validate: true,
                                                                        touched: true,
                                                                    });
                                                                },
                                                            },
                                                        }}
                                                        options={amounts}
                                                        styleConfig={{
                                                            select: select(),
                                                            singleValue: singleValue(),
                                                            option: singleOption(),
                                                        }}
                                                    />
                                                    <Error field="newCreditLimit" />
                                                </Right>
                                            </Container>
                                            <Container>
                                                <Left>
                                                    <Font styleConfig={{ root: bodyLabel() }}>
                                                        {fm(messages.changePageAllowDowngrade)}
                                                    </Font>
                                                </Left>
                                                <Right>
                                                    <CheckboxField
                                                        checked={form.data.allowDowngrade}
                                                        checkedIcon={
                                                            <Icon
                                                                icon={["fa", "times"]}
                                                                color="#747578"
                                                            />
                                                        }
                                                        onClick={() => {
                                                            processChange({
                                                                field: "allowDowngrade",
                                                                value: !form.data.allowDowngrade,
                                                                validate: true,
                                                                touched: true,
                                                            });
                                                        }}
                                                        styleConfig={{
                                                            root: checkbox(),
                                                            checked: {},
                                                        }}
                                                    />
                                                    <Error field="allowDowngrade" />
                                                </Right>
                                            </Container>
                                            <Container>
                                                <Left>
                                                    <Font styleConfig={{ root: bodyLabel() }}>
                                                        {fm(messages.changePageEmploymentType)}
                                                    </Font>
                                                </Left>
                                                <Right>
                                                    <SelectField
                                                        name="employmentType"
                                                        value={getOption(
                                                            types,
                                                            form.data.employmentType
                                                        )}
                                                        icon={
                                                            <Icon
                                                                icon={["fa", "caret-down"]}
                                                                size="1x"
                                                            />
                                                        }
                                                        inputConfig={{
                                                            select: {
                                                                value: form.data.employmentType,
                                                                onChange: (value) => {
                                                                    processChange({
                                                                        field: "employmentType",
                                                                        value: value?.value,
                                                                        validate: true,
                                                                        touched: true,
                                                                    });
                                                                },
                                                            },
                                                        }}
                                                        options={types}
                                                        styleConfig={{
                                                            select: select(),
                                                            singleValue: singleValue(),
                                                            option: singleOption(),
                                                        }}
                                                    />
                                                    <Error field="employmentType" />
                                                </Right>
                                            </Container>
                                            <Container>
                                                <Left>
                                                    <Font styleConfig={{ root: bodyLabel() }}>
                                                        {fm(messages.changePageMonthlyIncomeGross)}
                                                    </Font>
                                                </Left>
                                                <Right>
                                                    <TextField
                                                        inputConfig={{
                                                            name: "monthlyIncomeGross",
                                                            placeholder: "Skriv här",
                                                            value: form.data.monthlyIncomeGross,
                                                            onFocus: () => {
                                                                processFocus("monthlyIncomeGross");
                                                            },
                                                            onChange: (e) => {
                                                                processChange({
                                                                    field: "monthlyIncomeGross",
                                                                    value: e.target.value,
                                                                });
                                                            },
                                                            onBlur: () => {
                                                                processBlur("monthlyIncomeGross");
                                                            },
                                                        }}
                                                        styleConfig={{ root: textField() }}
                                                    />
                                                    <Error field="monthlyIncomeGross" />
                                                </Right>
                                            </Container>
                                            <Container>
                                                <Left>
                                                    <Font styleConfig={{ root: bodyLabel() }}>
                                                        {fm(messages.changePageLivingExpenses)}
                                                    </Font>
                                                </Left>
                                                <Right>
                                                    <TextField
                                                        inputConfig={{
                                                            name: "livingExpenses",
                                                            placeholder: "Skriv här",
                                                            value: form.data.livingExpenses,
                                                            onFocus: () => {
                                                                processFocus("livingExpenses");
                                                            },
                                                            onChange: (e) => {
                                                                processChange({
                                                                    field: "livingExpenses",
                                                                    value: e.target.value,
                                                                });
                                                            },
                                                            onBlur: () => {
                                                                processBlur("livingExpenses");
                                                            },
                                                        }}
                                                        styleConfig={{ root: textField() }}
                                                    />
                                                    <Error field="livingExpenses" />
                                                </Right>
                                            </Container>
                                            <Container>
                                                <Left>
                                                    <Font styleConfig={{ root: bodyLabel() }}>
                                                        {fm(messages.changePageLoanExpenses)}
                                                    </Font>
                                                </Left>
                                                <Right>
                                                    <TextField
                                                        inputConfig={{
                                                            name: "loanExpenses",
                                                            placeholder: "Skriv här",
                                                            value: form.data.loanExpenses,
                                                            onFocus: () => {
                                                                processFocus("loanExpenses");
                                                            },
                                                            onChange: (e) => {
                                                                processChange({
                                                                    field: "loanExpenses",
                                                                    value: e.target.value,
                                                                });
                                                            },
                                                            onBlur: () => {
                                                                processBlur("loanExpenses");
                                                            },
                                                        }}
                                                        styleConfig={{ root: textField() }}
                                                    />
                                                    <Error field="loanExpenses" />
                                                </Right>
                                            </Container>
                                            <Container>
                                                <Left>
                                                    <Font styleConfig={{ root: bodyLabel() }}>
                                                        {fm(messages.changePageGuaranteeObligation)}
                                                    </Font>
                                                </Left>
                                                <Right>
                                                    <SelectField
                                                        name="guarantorObligation"
                                                        value={getOption(
                                                            guarantorObligationOptions,
                                                            form.data.guarantorObligation
                                                        )}
                                                        icon={
                                                            <Icon
                                                                icon={["fa", "caret-down"]}
                                                                size="1x"
                                                            />
                                                        }
                                                        inputConfig={{
                                                            select: {
                                                                value: form.data
                                                                    .guarantorObligation,
                                                                onChange: (value) => {
                                                                    processChange({
                                                                        field: "guarantorObligation",
                                                                        value: value?.value,
                                                                        validate: true,
                                                                        touched: true,
                                                                    });
                                                                },
                                                            },
                                                        }}
                                                        options={guarantorObligationOptions}
                                                        styleConfig={{
                                                            select: select(),
                                                            singleValue: singleValue(),
                                                            option: singleOption(),
                                                        }}
                                                    />
                                                    <Error field="guarantorObligation" />
                                                </Right>
                                            </Container>
                                            {form.data.guarantorObligation === "Ja" && (
                                                <Container>
                                                    <Left>
                                                        <Font styleConfig={{ root: bodyLabel() }}>
                                                            {fm(
                                                                messages.chnagePageGuaranteeObligationAmount
                                                            )}
                                                        </Font>
                                                    </Left>
                                                    <Right>
                                                        <TextField
                                                            inputConfig={{
                                                                name: "guarantorObligationAmount",
                                                                placeholder: "Skriv här",
                                                                value: form.data
                                                                    .guarantorObligationAmount,
                                                                onFocus: () => {
                                                                    processFocus(
                                                                        "guarantorObligationAmount"
                                                                    );
                                                                },
                                                                onChange: (e) => {
                                                                    processChange({
                                                                        field: "guarantorObligationAmount",
                                                                        value: e.target.value,
                                                                    });
                                                                },
                                                                onBlur: () => {
                                                                    processBlur(
                                                                        "guarantorObligationAmount"
                                                                    );
                                                                },
                                                            }}
                                                            styleConfig={{ root: textField() }}
                                                        />
                                                        <Error field="guarantorObligationAmount" />
                                                    </Right>
                                                </Container>
                                            )}
                                            <Container>
                                                <Left>
                                                    <Font styleConfig={{ root: bodyLabel() }}>
                                                        {fm(messages.changePageLoanPurpose)}
                                                    </Font>
                                                </Left>
                                                <Right>
                                                    <SelectField
                                                        name="loanPurpose"
                                                        value={getOption(
                                                            loanPurposeOptions,
                                                            form.data.loanPurpose
                                                        )}
                                                        icon={
                                                            <Icon
                                                                icon={["fa", "caret-down"]}
                                                                size="1x"
                                                            />
                                                        }
                                                        inputConfig={{
                                                            select: {
                                                                value: form.data.loanPurpose,
                                                                onChange: (value) => {
                                                                    processChange({
                                                                        field: "loanPurpose",
                                                                        value: value?.value,
                                                                        validate: true,
                                                                        touched: true,
                                                                    });
                                                                },
                                                            },
                                                        }}
                                                        options={loanPurposeOptions}
                                                        styleConfig={{
                                                            select: select(),
                                                            singleValue: singleValue(),
                                                            option: singleOption(),
                                                        }}
                                                    />
                                                    {form.data.loanPurpose === "Annat" && (
                                                        <TextField
                                                            inputConfig={{
                                                                name: "otherLoanPurpose",
                                                                placeholder: "Skriv här",
                                                                value: form.data.otherLoanPurpose,
                                                                onFocus: () => {
                                                                    processFocus(
                                                                        "otherLoanPurpose"
                                                                    );
                                                                },
                                                                onChange: (e) => {
                                                                    processChange({
                                                                        field: "otherLoanPurpose",
                                                                        value: e.target.value,
                                                                    });
                                                                },
                                                                onBlur: () => {
                                                                    processBlur("otherLoanPurpose");
                                                                },
                                                            }}
                                                            styleConfig={{
                                                                root: {
                                                                    ...textField(),
                                                                    marginTop: "8px",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                    <Error field="otherLoanPurpose" />
                                                </Right>
                                            </Container>
                                            <Container>
                                                <Left>
                                                    <Font styleConfig={{ root: bodyLabel() }}>
                                                        {fm(messages.changePageMaritialStatus)}
                                                    </Font>
                                                </Left>
                                                <Right>
                                                    <SelectField
                                                        name="maritalStatus"
                                                        value={getOption(
                                                            maritalOptions,
                                                            form.data.maritalStatus
                                                        )}
                                                        icon={
                                                            <Icon
                                                                icon={["fa", "caret-down"]}
                                                                size="1x"
                                                            />
                                                        }
                                                        inputConfig={{
                                                            select: {
                                                                value: form.data.maritalStatus,
                                                                onChange: (value) => {
                                                                    processChange({
                                                                        field: "maritalStatus",
                                                                        value: value?.value,
                                                                        validate: true,
                                                                        touched: true,
                                                                    });
                                                                },
                                                            },
                                                        }}
                                                        options={maritalOptions}
                                                        styleConfig={{
                                                            select: select(),
                                                            singleValue: singleValue(),
                                                            option: singleOption(),
                                                        }}
                                                    />
                                                    <Error field="maritalStatus" />
                                                </Right>
                                            </Container>
                                            <Container>
                                                <Left>
                                                    <Font styleConfig={{ root: bodyLabel() }}>
                                                        {fm(messages.changePageChildren)}
                                                    </Font>
                                                </Left>
                                                <Right>
                                                    <SelectField
                                                        name="children"
                                                        value={getOption(
                                                            children,
                                                            form.data.children
                                                        )}
                                                        icon={
                                                            <Icon
                                                                icon={["fa", "caret-down"]}
                                                                size="1x"
                                                            />
                                                        }
                                                        inputConfig={{
                                                            select: {
                                                                value: form.data.children,
                                                                onChange: (value) => {
                                                                    processChange({
                                                                        field: "children",
                                                                        value: value?.value,
                                                                        validate: true,
                                                                        touched: true,
                                                                    });
                                                                },
                                                            },
                                                        }}
                                                        options={children}
                                                        styleConfig={{
                                                            select: select(),
                                                            singleValue: singleValue(),
                                                            option: singleOption(),
                                                        }}
                                                    />
                                                </Right>
                                            </Container>
                                            <Container>
                                                <Left>
                                                    <Font styleConfig={{ root: bodyLabel() }}>
                                                        {fm(messages.changePageAllowBisnodeCheck)}
                                                    </Font>
                                                </Left>
                                                <Right>
                                                    <CheckboxField
                                                        checked={form.data.allowBisnodeCheck}
                                                        checkedIcon={
                                                            <Icon
                                                                icon={["fa", "times"]}
                                                                color="#747578"
                                                            />
                                                        }
                                                        onClick={() => {
                                                            processChange({
                                                                field: "allowBisnodeCheck",
                                                                value: !form.data.allowBisnodeCheck,
                                                                validate: true,
                                                                touched: true,
                                                            });
                                                        }}
                                                        styleConfig={{
                                                            root: checkbox(),
                                                            checked: {},
                                                        }}
                                                    />
                                                    <Error field="allowBisnodeCheck" />
                                                </Right>
                                            </Container>
                                            <Column>
                                                {!isValid && form.touched.length > 0 && (
                                                    <Root>
                                                        <Font
                                                            styleConfig={{
                                                                root: bottomFormError(),
                                                            }}>
                                                            {fm(messages.changePageValidationError)}
                                                        </Font>
                                                    </Root>
                                                )}
                                                <ButtonField
                                                    type="submit"
                                                    styleConfig={{
                                                        root: !isValid
                                                            ? buttonSubmitDisabled()
                                                            : buttonSubmit(),
                                                    }}
                                                    onClick={async (e) => {
                                                        e.preventDefault();
                                                        if (!isValid) return;
                                                        setIsModalOpen(true);
                                                    }}>
                                                    {fm(messages.applicationSendButton)}
                                                </ButtonField>
                                            </Column>
                                        </Root>
                                    </BoxContainer>
                                </GridContent>
                            </Grid>
                        </GridContainer>
                    </>
                )}
            </FlexColumn>
        </Authenticator>
    );
}
